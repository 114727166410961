<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <div class="restaurant"></div>

        <div class="title">{{ $t("home.history") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="filters">
        <v-text-field
          v-model="start_date"
          @input="fetch()"
          :style="[start_date ? { opacity: '1' } : { opacity: '0.5' }]"
          type="date"
          filled
          :label="$t('history.start_date')"
          pattern="YYYY-MM-DD"
          :max="today"
        ></v-text-field>

        <span>-</span>

        <v-text-field
          v-model="end_date"
          @input="fetch()"
          :style="[end_date ? { opacity: '1' } : { opacity: '0.5' }]"
          type="date"
          filled
          :label="$t('history.end_date')"
          :max="today"
        ></v-text-field>
        <button @click="generatePDF()" class="btn pdf">PDF</button>
      </div>
      <div class="orders">
        <order-card
          v-for="(order, index) in orders"
          :key="index"
          :order="order"
        ></order-card>
      </div>

    </div>

  </div>
</template>
<script>
import OrderCard from "../../components/restaurant/OrderCard.vue";
import moment from "moment";

export default {
  components: {
    OrderCard,
  },
  mounted() {
    this.today = moment().format("YYYY-MM-DD");

    this.fetch();
  },
  methods: {
    // selectedOption(val) {
    //   this.selected_option = val;
    // },
    generatePDF() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname +
            "/orders-export?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.global_error.message = "The report has been sent to your email.";
          this.global_error.show = true;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname +
            "/shop/history?start_date=" +
            this.start_date +
            "&end_date=" +
            this.end_date,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();

          this.orders = resp.data;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      orders: [],
      today: "",
      start_date: "",
      end_date: "",
      // selected_option: 1,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 100%;
  /* overflow-y: scroll; */
}
.filters {
  display: flex;
  gap: 8px;
  align-items: center;
}
input {
  text-align: center;
  padding-right: 8px;
}
.orders {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}

.pdf {
  height: 40px;
  width: 40px;
  font-size: 14px;
  justify-content: center;
}
</style>

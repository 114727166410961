<template>
  <div class="menu">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.menu") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content-menu">
      <h4>{{ "Kortit" }}</h4>
      <div class="categories">
        <button
          class="category"
          v-for="postcard in restaurant_postcards.data"
          @click="editPostcard(postcard)"
          :key="postcard.id"
        >
          {{ postcard.title + ": " + formatPrice(postcard.price) }}
        </button>
      </div>
      <h4>{{ "Tuotekategoriat" }}</h4>
      <div class="categories">
        <div
          class="parent-category"
          v-for="category in restaurant_categories.data"
          :key="category.id"
        >
          <router-link class="category" :to="'/category/' + category.id"
            >{{ category.title }}
          </router-link>
          <router-link
            class="subcategory"
            v-for="subcategory in category.subcategories"
            :to="'/category/' + subcategory.id"
            >{{ subcategory.title }}
          </router-link>
        </div>
      </div>
      <button @click="showModal('category_modal')" class="fill">
        {{ $t("restaurant_menu.add_new_category") }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <new-category-modal></new-category-modal>
      <postal-card-modal :card="selected_postcard"></postal-card-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import NewCategoryModal from "../../components/restaurant/NewCategoryModal.vue";
import PostalCardModal from "../../components/restaurant/PostalCardModal.vue";

export default {
  components: {
    BackButton,
    NewCategoryModal,
    PostalCardModal,
  },
  mounted() {
    // this.iOSBottomGapFixer(".content-menu");

    this.fetchCategories();
  },
  methods: {
    editPostcard(postcard) {
      this.showModal("post_card_modal");
      this.selected_postcard = postcard;
    },
  },
  data() {
    return {
      selected_postcard: {
        title: "",
        price: "",
        description: "",
        max_length_chars: "",
      },
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content-menu {
  min-height: 70%;
  height: 70%;
  /* overflow-y: scroll; */
  padding: 16px;
  padding-top: 70px;
  padding-bottom: 120px;
  max-width: 768px;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.categories {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  width: 100%;
}
.parent-category {
  gap: 6px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.category {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;
  border: 1px solid #8f93bf;
  border-radius: 8px;
  height: 48px;
  font-size: 14px;
  color: #412250;
}
.subcategory {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  border: 1px solid #8f93bf;
  border-radius: 8px;
  height: 40px;
  font-size: 14px;
  color: #140a18;
}
button.fill {
  position: fixed;
  bottom: 68px;
  width: 92%;
  margin: auto;
  background-color: #5a187a;
  border: 1px solid #5a187a;
}

h4 {
  font-weight: 500;
  font-size: 18px;
  color: #b3b3b3;
  margin: 24px 0 16px;
}

@media screen and (min-width: 811px) {
  button {
    max-width: 768px;
  }
}
</style>

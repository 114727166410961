<template>
  <div class="profile">
    <v-snackbar
      color="#5a187a"
      max-width="5"
      width="5"
      dark
      v-model="show_message"
    >
      {{ message }}

      <template :dark="true" v-slot:action="{ attrs }">
        <v-btn
          color="#fafafa"
          text
          v-bind="attrs"
          @click="show_message = false"
        >
          {{ $t("main_page.close") }}
        </v-btn>
      </template>
    </v-snackbar>
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.profile") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="form parent">
        <div class="actions">
          <button class="link">
            <div class="icon"></div>
            <a id="menu" :href="menu"> {{ $t("profile.open_link") }} </a>
          </button>
          <button @click="copyLink" class="copy">
            <div class="icon"></div>
            {{ $t("profile.copy_link") }}
          </button>
        </div>
        <div class="form">
          <div class="input">
            <label class="deactive" for="name">{{ $t("profile.owner") }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="name"
              v-model="person.name"
            />
          </div>

          <div class="input">
            <label class="deactive" for="email">{{
              $t("profile.email")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="email"
              v-model="person.email"
            />
          </div>

          <div class="input phone_number">
            <label class="deactive" for="phone_number">{{
              $t("information.phone_number")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="phone_number"
              v-model="person.phone_number"
            />
          </div>

          <div class="input restaurant_name">
            <label class="deactive" for="restaurant_name">{{
              $t("profile.name")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="restaurant_name"
              v-model="person.restaurant_name"
            />
          </div>

          <div class="input">
            <label class="deactive" for="zip_code">{{
              $t("information.zip_code")
            }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="zip_code"
              v-model="person.zip_code"
            />
          </div>

          <div class="input">
            <label class="deactive" for="bic">{{ "BIC" }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="bic"
              v-model="person.bic"
            />
          </div>

          <div class="input">
            <label class="deactive" for="iban">{{ "IBAN" }}</label>
            <input
              class="active"
              disabled
              type="text"
              id="iban"
              v-model="person.iban"
            />
          </div>
        </div>
      </div>

      <button @click="showModal('logout_modal')" class="outline logout">
        {{ $t("settings.logout") }}
      </button>
    </div>

    <div id="blur_background" class="blur-bg">
      <logout-modal></logout-modal>
    </div>
  </div>
</template>

<script>
import BackButton from "../components/common/BackButton.vue";
import LogoutModal from "../components/common/LogoutModal.vue";

export default {
  components: { BackButton, LogoutModal },
  mounted() {
    this.fetch();
  },
  data() {
    return {
      message: "",
      show_message: false,
      menu: "",
      qr: "",
      person: {
        name: "",
        email: "",
        restaurant_name: "",
        zip_code: "",
        phone_number: "",
        bic: "",
        iban: "",
      },
    };
  },
  methods: {
    copyLink(evt) {
      let copiedText = document.getElementById("menu");

      navigator.clipboard.writeText(copiedText.getAttribute("href")).then(
        () => {
          this.show_message = true;
          this.message = "Copied!";
        },
        () => {
          console.error("there's an error");
        }
      );
    },
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/shop/shops", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.person.restaurant_name = resp.data.shop.title;
          this.person.phone_number =
            resp.data.shop.phone_number;
          this.person.zip_code = resp.data.shop.zip_code;
          this.person.iban = resp.data.shop.iban;
          this.person.bic = resp.data.shop.bic;
          this.person.name = resp.data.shop.user.name;
          this.person.email = resp.data.shop.user.email;
          this.menu =
            this.$customer_app +
            "/shops/" +
            resp.data.shop.id;
         
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  height: 85vh;
  align-items: center;
  overflow-y: scroll;
  padding: 70px 16px;
  gap: 16px;
  justify-content: space-between;
}

.form {
  width: 100%;
}

.parent {
  width: 100%;
  align-items: center;
  gap: 16px;
}
.actions {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.link,
.copy {
  position: relative;
  border: 1px solid #8f93bf3f;
  border-radius: 8px;
  min-height: 40px;
  width: 48%;
  font-size: 14px;
  color: #412250;
}

.link a {
  width: 100%;
}

.link .icon {
  width: 22px;
  height: 22px;
  background-size: 22px;
}

.copy .icon {
  width: 16px;
  height: 16px;
  background-size: 16px;
}

.link .icon {
  left: 22px;
  top: 8px;
  position: absolute;
  background-image: url("../assets/restaurant/profile/link.svg");
}

.copy .icon {
  left: 22px;
  top: 10px;
  position: absolute;
  background-image: url("../assets/restaurant/profile/copy.svg");
}

.restaurant_name label,
.restaurant_name input {
  width: 50%;
}
.phone_number label {
  width: 40%;
}
.phone_number input {
  width: 60%;
}
.qr {
  width: 136px;
  height: 136px;
}

.logout {
  width: 100%;
  min-height: 48px;
  border: 1px solid #5a187a;
  color: #5a187a;
}
</style>

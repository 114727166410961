<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.restaurant_info") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="photos">
        <div class="photo_input">
          <label
            v-if="main_photo.url"
            @click.prevent="deletePhoto(main_photo.id, 0, main_photo.url)"
            for="main_photo"
            :style="[
              main_url
                ? { 'background-image': 'url(' + main_url + ')' }
                : { 'background-image': 'url(' + main_photo.url + ')' },
            ]"
          ></label>
          <label v-else for="main_photo" id="upload"></label>

          <div
            @click.prevent="deletePhoto(main_photo.id, 0, main_photo.url)"
            class="trash"
            v-if="main_photo.url"
          ></div>
          <span v-else>
            {{ $t("information.main_pic") }}
          </span>

          <input
            @change="setPhoto"
            type="file"
            name="main_photo"
            id="main_photo"
          />
        </div>
      </div>

      <div class="form">
        <v-text-field
          v-model="shop.title"
          filled
          :label="$t('information.title')"
        ></v-text-field>

        <v-textarea
          v-model="shop.banner_text"
          filled
          maxlength="100"
          rows="3"
          :label="$t('info.banner_text') + ' (' + maxCharacters + '/100)'"
        ></v-textarea>

        <v-textarea
          v-model="shop.address"
          filled
          rows="2"
          :label="$t('info.address')"
        ></v-textarea>

        <v-text-field
          v-model="shop.phone_number"
          filled
          :label="$t('information.phone_number')"
        ></v-text-field>

        <v-textarea
          v-model="shop.about"
          filled
          rows="4"
          :label="$t('home.info')"
        ></v-textarea>
      </div>

      <div class="hours">
        <button @click="showModal('working_hours_modal')" class="light">
          {{ $t("information.choose_working") }}
        </button>
      </div>

      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <working-hours-modal
        :opening_times="shop.shop_hours"
        @opening_times="openingTimes"
      ></working-hours-modal>
      <delete-photo-modal
        :index="selected_photo_index"
        :photo_id="selected_photo_id"
        :url="selected_photo_url"
        @deleted_photo="deletedPhoto"
      ></delete-photo-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";
import DeletePhotoModal from "../../../components/restaurant/DeletePhotoModal.vue";
import WorkingHoursModal from "../../../components/restaurant/WorkingHoursModal.vue";

export default {
  components: {
    BackButton,
    WorkingHoursModal,
    DeletePhotoModal,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/shop/shops", this.restaurant_token.config)
        .then((resp) => {
          this.shop = resp.data.shop;
          this.main_photo = resp.data.shop.photo ? resp.data.shop.photo : {};
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      this.global_loading.show = true;
      this.shop.delivery_times = JSON.stringify(this.shop.delivery_hours);

      this.$http
        .patch(
          this.$hostname + "/shop/shops/" + this.shop.id,
          this.shop,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/");
    },
    setPhoto(e) {
      let fd = new FormData();

      this.photo = e.target.files[0] || e.dataTransfer.files[0];

      fd.append("photo", this.photo);

      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/shop/photos", fd, this.restaurant_token.config)
        .then((resp) => {
          this.fetch();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deletePhoto(photo_id, index, url) {
      this.selected_photo_id = photo_id;

      this.selected_photo_index = index;

      this.selected_photo_url = url;

      this.showModal("delete_photo_modal");
    },
    deletedPhoto(photo_id, index) {
      switch (index) {
        case 0:
          this.main_photo = {};

          break;
        case 1:
          this.second_photo = {};

          break;
        case 2:
          this.third_photo = {};

          break;
        case 3:
          this.fourth_photo = {};

          break;

        default:
          break;
      }

      this.fetch();
    },
    openingTimes(val) {
      this.opening_times = val;
      this.save();
    },
  },
  computed: {
    maxCharacters() {
      return this.banner_text ? this.banner_text.length : 0;
    },
  },
  data() {
    return {
      shop: {},
      photo: "",
      main_photo: {},
      main_url: "",
      second_photo: {},
      second_url: "",
      third_photo: {},
      third_url: "",
      fourth_photo: {},
      fourth_url: "",
      title: "",
      phone_number: "",
      address: "",
      about: "",
      reservation_note: "",
      banner_text: "",
      opening_times: [],
      selected_photo_id: 0,
      selected_photo_url: "",
      selected_photo_index: 0,
      default_reservation_length: 1,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.form {
  padding: 16px 0 0;
}

.photos {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #f0edf2;
}

.photo_input {
  width: 48.5%;
  padding: 0;
  position: relative;
}

.photo_input label {
  width: 100%;
  min-height: 100%;
  background-size: cover;
}

.photo_input span {
  position: absolute;
  bottom: 0;
}

.phone_number label {
  width: 44%;
}

label.banner_text {
  width: 50%;
  min-width: 42%;
}
.phone_number input {
  width: 56%;
}
textarea {
  width: 65%;
}
textarea#about {
  height: 157px;
  width: 75%;
}

.hours {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
  border-bottom: 1px solid #f0edf2;
}
.hours button {
  font-size: 14px;
  width: 100%;
  border-radius: 8px;
  height: 42px;
}

h4 {
  padding: 16px 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
}

.trash {
  background-image: url("../../../assets/restaurant/home/trash.svg");
  background-size: 40px;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-blend-mode: multiply;
  background-color: #f0edf2b4;
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}
</style>

<template>
  <div id="post_card_modal" class="modal">
    <div class="question">
      <div class="input">
        <label class="deactive" for="title">{{ $t("information.name") }}</label>
        <input class="active" type="text" id="title" v-model="card.title" />
      </div>

      <div class="input">
        <label class="deactive" for="title">{{ $t("history.price") }}</label>

        <input class="active" type="text" id="price" v-model="card.price" />
      </div>
      <div class="input">
        <label class="deactive" for="title">{{ "Max length" }}</label>

        <input
          class="active"
          type="text"
          id="max_length_chars"
          v-model="card.max_length_chars"
        />
      </div>
      <div class="input">
        <label class="deactive" for="description">
          {{ $t("restaurant_menu.description") }}
        </label>
        <input
          class="active"
          type="text"
          id="description"
          v-model="card.description"
        />
      </div>

      <div class="row">
        <button @click="save" class="fill">
          {{ $t("order.submit") }}
        </button>

        <button @click="deleteCard()" class="icon delete"></button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PostalCardModal",
  methods: {
    save() {
      if (this.card.title.length) {
        this.global_loading.show = true;
        let data = {
          title: this.card.title,
          description: this.card.description,
          price: this.card.price,
          max_length_chars: this.card.max_length_chars,
        };
        this.$http
          .patch(
            this.$hostname + "/shop/postal-cards/" + this.card.id,
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.fetchCategories();

            this.closeModal("post_card_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
    deleteCard() {
      this.$http
        .delete(
          this.$hostname + "/shop/postal-cards/" + this.card.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.fetchCategories();

          this.closeModal("post_card_modal");
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  props: {
    card: {
      title: "",
      price: "",
      description: "",
      max_length_chars: "",
    },
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.modal {
  height: 460px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.row {
  display: flex;
  gap: 8px;
  align-items: center;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
select {
  width: 100%;
  background-image: url("../../assets/common/select.svg");
  background-position-x: right;
  background-position-y: center;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
}
</style>

<template>
  <div
    :id="'order-' + order.id"
    :class="[!order.progress_status ? 'new' : 'old', 'order', 'btn']"
    :style="[
      order.status == 'rejected' ? { 'border-color': 'transparent' } : '',
    ]"
  >
    <div v-if="order.receive_at" class="row">
      <!-- <div class="caution">
        {{ $t("order.pre_order") + " " }}
      </div> -->
      &nbsp;
      <div class="warn">
        {{ " " + $t("order.pre_ordering") + formatDatetime(order.receive_at) }}
      </div>
    </div>
    <div class="row">
      <div class="type">
        <div
          :class="[
            order.order_type == 'pick_up' ? 'pick_up' : 'delivery',
            'icon',
          ]"
        ></div>
        <div class="number">
          {{
            order.order_type == "pick_up"
              ? $t("order.pick_up")
              : order.order_type == "delivery"
              ? $t("order.delivery")
              : "Not defined"
          }}
        </div>
        <counter
          v-if="$route.name != 'History'"
          @time_over="timeOver"
          :end="order.ready_at"
        ></counter>
        <span v-if="order.status == 'rejected'" class="closed">{{
          $t("order.rejected")
        }}</span>
      </div>

      <div class="total">
        <span>
          {{ formatPrice(total) }}
        </span>
      </div>
      <print-button :order="order"></print-button>
    </div>
    <div class="row">
      <span class="number">{{
        (order.order_type == "at_restaurant"
          ? $t("order.table") + " " + order.table_number
          : "") +
        " #" +
        order.id
      }}</span>
      <div class="datetime">{{ formatDatetime(order.created_at) }}</div>
    </div>
    <div class="row">
      <div
        :class="[
          order.payment_status == 1 ? 'pay-paid' : 'pay-unpaid',
          'payment',
        ]"
      >
        {{
          order.payment_method == "mobile"
            ? "Mobile"
            : order.payment_method == "online"
            ? "Online"
            : order.payment_method == "cash"
            ? $t("order.cash_pay")
            : order.payment_method == "at_place"
            ? $t("cart.at_place")
            : "Not defined"
        }}
        <span
          :class="[order.payment_status == 1 ? 'paid' : 'unpaid', 'payment']"
        >
          {{
            order.payment_status == 1
              ? `(${$t("order.paid")})`
              : `(${$t("cart.unpaid")})`
          }}
        </span>
      </div>
      <div class="count">
        <!-- <div class="items-count">
          {{ order.items_count + " " + $t("restaurant_menu.items") }}
        </div> -->
      </div>
    </div>
    <!-- <div class="row" v-if="order.promotion_code">
      <div class="title">
        {{ "Promotional code: " + order.promotion_code.code }}
      </div>
      <div class="price">
        {{ "Discount: " + order.promotion_code.discount + "%" }}
      </div>
    </div> -->
    <div class="items">
      <div class="dish" v-for="(dish, index) in products" :key="index">
        <div class="item">
          <div
            class="photo"
            :style="[
              dish.item.photo
                ? {
                    'background-image': 'url(' + dish.item.photo + ')',
                  }
                : '',
            ]"
          ></div>
          <div class="data">
            <div class="row">
              <div class="info">
                <div class="quantity">x1</div>

                <div class="caption">
                  {{
                    dish.item.title +
                    (dish.item.category
                      ? " (" + dish.item.category.title + ")"
                      : "")
                  }}
                </div>
              </div>

              <!-- <div class="price">{{ formatPrice(item.price) }}</div> -->
              <div class="price" v-if="dish.item.discount > 0">
                <s>{{ formatPrice(productPrice(dish)) }}</s>
                &nbsp;
                {{ formatPrice(productPriceWithDiscount(dish)) }}
              </div>
              <div v-else class="price">
                {{ formatPrice(productPrice(dish)) }}
              </div>
            </div>
            <div class="description" v-html="dish.item.description_fi"></div>
            <div v-for="price in dish.prices" :key="price.id" class="extras">
              <div class="caption">
                -
                {{ (price.title ? price.title : "") + " " + price.description }}
              </div>
              <!-- <div class="info"> -->
              <!-- <div class="quantity">x1</div> -->
              <div class="price">
                {{
                  formatPrice(
                    price.price - (price.price * dish.item.discount) / 100
                  )
                }}
              </div>
              <!-- </div> -->
            </div>

            <h5 class="extras" v-if="dish.cards.length">
              {{ $t("cart.drinks") }}:
            </h5>
            <div class="extras" v-for="extra in dish.cards" :key="extra.id">
              <div class="caption">- {{ extra.title }}</div>
              <!-- <div class="info"> -->
              <!-- <div class="quantity">x1</div> -->
              <div class="price">{{ formatPrice(extra.price) }}</div>
              <!-- </div> -->
            </div>
          </div>
        </div>
      </div>

      <div v-if="order.order_type == 'delivery'" class="delivery_charge">
        <div class="caption">
          {{ $t("order.delivery_fee") }}
        </div>

        <div class="price">{{ formatPrice(order.delivery_charge) }}</div>
      </div>
    </div>
    <h3 v-if="order.comment">{{ $t("cart.comment") }}</h3>
    <div v-if="order.comment" class="additional">
      {{ order.comment }}
    </div>

    <div v-if="order.order_type == 'delivery'" class="delivery">
      <div v-if="order.address" class="address">
        <div class="icon"></div>
        <div class="desc">
          {{
            order.address.description +
            (order.address.zip_code ? ", " + order.address.zip_code : "") +
            (order.address.city ? " " + order.address.city : "") +
            (order.address.door_code
              ? " (" +
                $t("information.door_code") +
                ":" +
                order.address.door_code +
                ")"
              : "")
          }}
        </div>
      </div>
      <div v-if="order.address" class="phone">
        <div class="icon"></div>
        <div class="desc">{{ order.address.phone_number }}</div>
      </div>
    </div>

    <div v-if="order.order_type == 'pick_up'" class="delivery">
      <div v-if="order.user" class="phone">
        <div class="icon"></div>
        <div class="desc">{{ order.user.phone_number }}</div>
      </div>
    </div>

    <div v-if="$route.name != 'History'" class="actions">
      <button
        @click="acceptOrder(order)"
        v-if="!order.progress_status"
        class="fill accept"
      >
        {{ $t("order.accept") }}
      </button>
      <button
        v-if="!order.progress_status"
        @click="rejectModal(order)"
        class="outline reject"
      >
        {{ $t("order.reject") }}
      </button>
      <button
        @click="deliverOrder(order)"
        v-if="order.progress_status"
        class="outline deliver-pay"
      >
        {{
          $t("customer_home.done") +
          (order.progress_status && order.payment_method !== "online"
            ? " & " + $t("order.paid")
            : "")
        }}
      </button>
      <!-- <button
        @click="
          paidOrder(
            order.id,
            order.order_type,
            formatPrice(totalPrice(order.items) + order.delivery_charge),
            order.payment_status
          )
        "
        v-if="order.progress_status && order.payment_method !== 'online'"
        class="outline"
      >
        {{
          order.payment_status == 1 ? $t("order.cancel_paid") : $t("order.paid")
        }}
      </button> -->
      <!-- <button
        @click="resetModal(order)"
        v-if="order.progress_status"
        class="outline"
      >
        {{ $t("order.delay") }}
      </button> -->
    </div>
  </div>
</template>
<script>
import Counter from "../common/Counter.vue";
import PrintButton from "../common/PrintButton.vue";
import moment from "moment";

export default {
  components: {
    PrintButton,
    Counter,
  },
  methods: {
    rejectModal(order) {
      this.order_processing.data = order;
      this.showModal("reject_modal");
    },
    timeOver(val) {
      this.time_over = val;
    },
    productPrice(product) {
      let product_price = 0;

      if (product.prices.length) {
        product.prices.forEach((price) => {
          product_price += price.price;
        });
      }

      return product_price;
    },
    productPriceWithDiscount(product) {
      let product_price = 0;
      if (product.cards.length) {
        product.cards.forEach((card) => {
          product_price += card.price;
        });
      }

      if (product.prices.length) {
        product.prices.forEach((price) => {
          product_price +=
            price.price - (price.price * product.item.discount) / 100;
        });
      }

      return product_price;
    },
  },
  computed: {
    products() {
      return JSON.parse(this.order.json_items);
    },
    total() {
      const total = parseFloat(this.totalPrice(this.order.json_items));
      const delivery_charge = parseFloat(this.order.delivery_charge);

      return total + delivery_charge;
    },

    preferredDate() {
      if (this.order.ready_at) {
        let ready_at = new Date(this.order.ready_at.substring(0, 10));
        let now = new Date();

        return ready_at.getTime() <= now.getTime();
        // return (
        //   moment().format("D.M.YYYY") ===
        //   moment(this.order.ready_at).format("D.M.YYYY")
        // );
      }
      return false;
    },
  },
  data() {
    return {
      time_over: false,
    };
  },
  props: {
    order: {},
  },
};
</script>
<style scoped>
.order {
  /* height: 84px; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  gap: 12px;
  overflow-y: hidden;
  background-color: #fbf5f5;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.data {
  display: flex;
  width: calc(100% - 96px);
  flex-direction: column;
}
.type .icon {
  width: 32px;
  background-size: 32px;
}
.type .at_restaurant {
  background-image: url("../../assets/restaurant/home/dinein.svg");
}

.type .pick_up {
  background-image: url("../../assets/restaurant/home/pickup.svg");
}

.type .delivery {
  background-image: url("../../assets/restaurant/home/delivery_order.svg");
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.type,
.total {
  font-size: 14px;
  color: #737373;
  display: flex;
  align-items: center;
  gap: 8px;
}

span.number {
  font-weight: 400;
  font-size: 14px;
  color: #737373;
}
.datetime {
  font-weight: 300;
  font-size: 12px;
  color: #b3b3b3;
}
.total span {
  font-weight: 500;
  font-size: 14px;
  color: #4d4d4d;
}
.type {
  width: calc(100% - 100px);
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}
.count {
  display: flex;
  align-items: center;
  gap: 4px;
}
.payment,
.items-count {
  font-size: 12px;
  color: #737373;
}
.down {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  transition: 0.3s ease;
  background-image: url("../../assets/common/down.svg");
}

.items {
  display: flex;
  flex-direction: column;
  width: 100%;
  border-top: 1px solid #f0edf2;
  border-bottom: 1px solid #f0edf2;
  padding: 12px 0;
  gap: 12px;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
  height: 100%;
}

.price,
.quantity {
  color: #776282;
  font-size: 14px;
  display: flex;
}
.quantity {
  font-weight: 700;
  color: #4d4d4d;
}
.caption {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}
.info {
  display: flex;
  justify-content: flex-start;
  width: 70%;
  gap: 8px;
}
.dish {
  border-bottom: 1px solid #d9d4dd;
  padding-bottom: 6px;
}
.item,
.extras {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.description {
  padding: 6px 0;
  font-size: 14px;
  color: #737373;
}
h5 {
  text-align: left;
  width: 100%;
  font-weight: 300;
  font-size: 14px;
}
.compulsory {
  flex-direction: column;
}
.compulsory .extra {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.extras .caption {
  padding-left: 5px;
  font-weight: 600;
}
.delivery_charge {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.new {
  border: 1px solid #836d8f;
}
.old {
  border: 1px solid transparent;
}
h3 {
  font-size: 14px;
  text-align: left;
  color: #737373;
  width: 100%;
}
.additional {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
  background-color: #ffffff;
  border-radius: 8px;
  width: 100%;
  padding: 8px;
}
.delivery {
  border-top: 1px solid #f0edf2;
  width: 100%;
  padding: 8px 0;
}

.address,
.phone {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  flex-direction: row;
  padding: 4px 0;
}

.address .icon {
  background-image: url("../../assets/menu/location.svg");
  width: 24px;
}

.address .desc,
.phone .desc {
  font-weight: 300;
  font-size: 16px;
  color: #737373;
}

.phone .icon {
  background-image: url("../../assets/menu/phone.svg");
  width: 24px;
}

.actions {
  width: 100%;
  display: flex;
  gap: 8px;
}

.fill,
.outline {
  width: 49%;
  height: 40px;
  font-weight: normal;
  border: 1px solid #f0edf2;
  font-size: 14px;
}
.outline {
  background-color: transparent;
}
.paid {
  color: #48e639;
}
.unpaid {
  color: #5a187a;
}

.pay-paid {
  background-color: #e4fbe2;
  border-radius: 8px;
  padding: 4px 8px;
}
.pay-unpaid {
  border-radius: 8px;
  padding: 4px 8px;
  background-color: #f0edf2;
}

button.deliver-pay {
  width: 100%;
  border-color: #836d8f;
}

.accept {
  width: 70%;
}
.fill {
  border: 1px solid #5a187a;
  background-color: #5a187a;
}
.outline {
  color: #5a187a;
}

.reject {
  width: 29%;
}

.item .caption {
  font-weight: 700;
  color: #4d4d4d;
}
.closed {
  padding: 4px;
  border-radius: 8px;
}

.extras {
  padding-left: 4px;
}

.warn {
  font-weight: 600;
  font-size: 16px;
  text-decoration-line: underline;
  color: #ff6a4d;
  width: 100%;
  text-align: left;
}
.caution {
  font-weight: 600;
  font-size: 14px;
  color: #5a187a;
}

.photo {
  min-width: 80px;
  width: 80px;
  height: 60px;
  border-radius: 8px;
  /* background-image: url("../assets/customer/menu/item.png"); */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>

<template>
  <div id="category_modal" class="modal">
    <div class="question">
      <div class="input">
        <label class="deactive" for="title">{{ $t("information.name") }}</label>
        <input
          class="active"
          type="text"
          id="title"
          v-model="title"
          placeholder="i.g. Rose"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <div v-if="type == 'post-card'" class="input">
        <label class="deactive" for="title">{{ $t("history.price") }}</label>
        <input
          class="active"
          type="text"
          id="price"
          v-model="price"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <div v-if="type == 'post-card'" class="input">
        <label class="deactive" for="title">{{ "Max length" }}</label>
        <input
          class="active"
          type="text"
          id="max_length_chars"
          v-model="max_length_chars"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <div class="input">
        <label class="deactive" for="description">{{
          $t("restaurant_menu.description")
        }}</label>
        <input
          class="active"
          type="text"
          id="description"
          v-model="description"
          v-on:keyup.enter="addNewCategory()"
        />
      </div>
      <!-- type of category -->
      <div class="input">
        <label class="deactive" for="category">{{ "Category type" }}</label>
        <select name="type" v-model="type" id="type">
          <option value="category">
            {{ "Flower" }}
          </option>
          <option value="post-card">
            {{ "Postal Card" }}
          </option>
        </select>
      </div>

      <div class="input">
        <label class="deactive" for="category">{{ "Parent Category" }}</label>
        <select name="type" v-model="parent" id="type">
          <option
            :value="category"
            v-for="category in restaurant_categories.data"
          >
            {{ category.title }}
          </option>
        </select>
      </div>
      <button @click="addNewCategory" class="fill">
        {{ $t("order.submit") }}
      </button>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    addNewCategory() {
      if (this.title.length && this.description.length) {
        this.global_loading.show = true;
        let data = {
          title: this.title,
          description: this.description,
          type: this.type,
          index: 1,
          parent_id: this.parent.id,
          price: this.price,
          max_length_chars: this.max_length_chars,
        };
        this.$http
          .post(
            this.$hostname + "/shop/categories",
            data,
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.fetchCategories();
            this.closeModal("category_modal");
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title and description field are required!";
      }
    },
  },
  data() {
    return {
      title: "",
      type: "dish",
      description: "",
      price: 0,
      max_length_chars: 0,
      parent: {},
    };
  },
};
</script>
<style scoped>
.modal {
  height: 460px;
  gap: 12px;
  justify-content: flex-start;
  padding: 0;
}
.question {
  background-color: #ffffff;
  height: 100%;
  border-radius: 16px 16px 0 0;
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
select {
  width: 100%;
  background-image: url("../../assets/common/select.svg");
  background-position-x: right;
  background-position-y: center;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
}
</style>

<template>
  <div class="info">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/'"></back-button>
        <div class="title">{{ $t("home.social_links") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <!-- social and links -->

      <div class="form">
        <v-text-field
          v-model="shop.website"
          filled
          :label="$t('information.website')"
        ></v-text-field>

        <v-text-field
          v-model="shop.google_maps"
          filled
          label="Google Maps"
        ></v-text-field>

        <!-- <v-text-field v-model="facebook" filled label="Facebook"></v-text-field> -->

        <v-text-field
          v-model="shop.instagram"
          filled
          label="Instagram"
        ></v-text-field>

        <v-text-field v-model="shop.tiktok" filled label="TikTok"></v-text-field>

        <!-- <v-text-field v-model="twitter" filled label="Twitter"></v-text-field> -->
      </div>
      <button @click="done()" class="fill">{{ $t("information.save") }}</button>
    </div>
    <div id="blur_background" class="blur-bg">
      <calls-modal></calls-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../../components/common/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/shop/shops", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
          this.shop = resp.data.shop;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    save() {
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/shop/shops/" + this.shop.id,
          this.shop,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    done() {
      this.save();
      this.backToHome();
    },
    backToHome() {
      this.$router.push("/");
    },
  },
  data() {
    return {
      shop: {},
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  overflow-y: scroll;
  padding: 78px 16px 96px;
}
.form {
  padding: 16px 0 0;
}

button.fill {
  min-height: 48px;
  margin-top: 12px;
}
</style>

<template>
  <div class="item">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/category/' + category_id"></back-button>
        <div class="title">
          {{ edit ? item.title : $t("restaurant_menu.add_new_item") }}
        </div>
        <button
          @click="showModal('delete_item_modal')"
          class="icon delete"
        ></button>
      </div>
    </div>
    <div class="content">
      <div class="body">
        <!-- photo -->
        <div
          class="photo_input"
          :style="[
            url
              ? { 'background-image': 'url(' + url + ')' }
              : { 'background-image': 'url(' + item.photo + ')' },
          ]"
        >
          <label
            v-if="item.photo"
            for="photo_fill"
            @click.prevent="deletePhoto()"
          ></label>
          <label v-else for="photo" id="upload"></label>

          <div
            @click.prevent="deletePhoto()"
            class="trash"
            v-if="item.photo"
          ></div>
          <span v-else>{{ $t("restaurant_menu.food_pic") }}</span>

          <input @input="setPhoto" type="file" name="photo" id="photo" />
        </div>

        <!-- title -->
        <div class="input">
          <label class="deactive" for="title">{{
            $t("information.name")
          }}</label>
          <input
            @change="submitAfterRename"
            class="active"
            type="text"
            id="title"
            v-model="item.title"
            placeholder="i.g. cheese (L/V/G)"
          />
        </div>
        <div class="input">
          <label class="deactive" for="title">{{ "Index" }}</label>

          <input
            @change="submitAfterRename"
            class="active"
            type="text"
            id="index"
            v-model="item.index"
            placeholder="Index"
          />
        </div>

        <!-- category of item -->
        <div class="input">
          <label class="deactive" for="category">{{
            $t("restaurant_menu.category")
          }}</label>
          <select name="category" v-model="selected_category_id" id="category">
            <option
              v-for="category in categories"
              :key="category.id"
              :value="category.id"
            >
              {{ category.title }}
            </option>
          </select>
        </div>

        <!-- Suomi Description -->
        <div class="input">
          <label class="deactive desc" for="desc-fi">{{
            $t("restaurant_menu.description") + " (Suomi)"
          }}</label>
          <textarea
            @change="saveOnChange"
            class="active"
            type="text"
            id="desc-fi"
            v-model="item.description_fi"
          ></textarea>
        </div>

        <!-- English Description -->
        <div class="input">
          <label class="deactive desc" for="desc-en">{{
            $t("restaurant_menu.description") + " (English)"
          }}</label>
          <textarea
            @change="saveOnChange"
            class="active"
            type="text"
            id="desc-en"
            v-model="item.description_en"
          ></textarea>
        </div>

        <div class="row">
          <!-- Discount -->
          <div class="input">
            <label class="deactive" for="discount">{{
              $t("restaurant_menu.discount")
            }}</label>
            <input
              @change="saveOnChange"
              class="active"
              type="text"
              id="discount"
              v-model="item.discount"
            />
            <span>%</span>
          </div>
        </div>

        <div class="col-price" v-for="price in item.prices">
          <div class="row">
            <!-- price -->

            <div class="input">
              <label class="deactive" for="price">{{
                $t("information.name")
              }}</label>
              <input
                class="active"
                type="text"
                id="price"
                v-model="price.title"
              />
            </div>

            <div class="input">
              <label class="deactive" for="discount">{{
                $t("history.price")
              }}</label>
              <input
                class="active"
                type="text"
                id="discount"
                v-model="price.price"
              />
              <span>€</span>
            </div>
          </div>
          <div class="row">
            <div class="input">
              <label class="deactive" for="discount">{{ "Stock" }}</label>
              <input
                class="active"
                type="text"
                id="discount"
                v-model="price.stock"
              />
            </div>
            <div class="input">
              <label class="deactive desc-price" for="desc-en">{{
                $t("restaurant_menu.description")
              }}</label>
              <textarea
                class="active"
                type="text"
                id="desc-en"
                v-model="price.description"
              ></textarea>
            </div>
          </div>
          <div class="row">
            <button @click="deletePrice(price)" class="icon delete"></button>
            <button @click="updatePrice(price)" class="outline">
              {{ $t("info.save_changes") }}
            </button>
          </div>
        </div>

        <div class="col-price">
          <div class="row">
            <!-- price -->

            <div class="input">
              <label class="deactive" for="price">{{
                $t("information.name")
              }}</label>
              <input
                class="active"
                type="text"
                id="price"
                v-model="new_product_price.title"
              />
            </div>

            <div class="input">
              <label class="deactive" for="discount">{{
                $t("history.price")
              }}</label>
              <input
                class="active"
                type="text"
                id="discount"
                v-model="new_product_price.price"
              />
              <span>€</span>
            </div>
          </div>
          <div class="row">
            <div class="input">
              <label class="deactive" for="discount">{{ "Stock" }}</label>
              <input
                class="active"
                type="text"
                id="discount"
                v-model="new_product_price.stock"
              />
            </div>
            <div class="input">
              <label class="deactive desc-price" for="desc-en">{{
                $t("restaurant_menu.description")
              }}</label>
              <textarea
                class="active"
                type="text"
                id="desc-en"
                v-model="new_product_price.description"
              ></textarea>
            </div>
          </div>
          <button @click="createPrice(new_product_price)" class="outline">
            {{ $t("info.save_changes") }}
          </button>
        </div>

        <!-- tax -->
        <div class="row tax-row">
          <div class="tax">
            <input v-model="item.tax" type="checkbox" name="tax" id="tax" />
            <label for="tax">
              {{ $t("restaurant_menu.vat_note") }}
            </label>
          </div>

          <div class="tax-note">
            {{ $t("restaurant_menu.vat_desc") }}
          </div>
        </div>

        <button v-if="edit" @click="saveItem" class="fill">
          {{ $t("info.save_changes") }}
        </button>

        <button v-else @click="addNewItem" class="fill">
          {{ $t("restaurant_menu.add_item") }}
        </button>
      </div>
      <div id="blur_background" class="blur-bg">
        <delete-item-modal
          :title="item.title"
          :id="item.id"
          :category_id="$route.params.category_id"
        ></delete-item-modal>
      </div>
    </div>
  </div>
</template>
<script>
import DeleteItemModal from "../../components/restaurant/DeleteItemModal.vue";
import BackButton from "../../components/common/BackButton.vue";

export default {
  mounted() {
    this.setEditing();
    this.fetchCategories();
    this.setCategories();
    this.iOSBottomGapFixer(".content");

    let isSafari =
      navigator.vendor.match(/apple/i) &&
      !navigator.userAgent.match(/crios/i) &&
      !navigator.userAgent.match(/fxios/i) &&
      !navigator.userAgent.match(/Opera|OPT\//);

    let container = document.querySelector(".body");

    if (isSafari) {
      // Safari browser is used
      container.style.height = "62vh";
    } else {
      // Other browser is used
    }
  },
  components: {
    DeleteItemModal,
    BackButton,
  },
  methods: {
    setCategories() {
      this.restaurant_categories.data.forEach((category) => {
        if (category.subcategories.length) {
          category.subcategories.forEach((subcategory) => {
            this.categories.push(subcategory);
          });
        } else {
          this.categories.push(category);
        }
      });
    },
    async fetch() {
      await this.$http
        .get(
          this.$hostname + "/shop/products/" + this.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.item = resp.data.result;
          if (resp.data.result.description_en) {
            this.item.description_en = resp.data.result.description_en.replace(
              /<br\s*[\/]?>/gi,
              "\n"
            );
          }
          if (resp.data.result.description_fi) {
            this.item.description_fi = resp.data.result.description_fi.replace(
              /<br\s*[\/]?>/gi,
              "\n"
            );
          }
          this.selected_category_id = resp.data.result.category.id;
          // console.log(this.selected_category);

          // this.item.price = this.replaceDot(resp.data.result.price);

          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    setPhoto(e) {
      this.item.photo = e.target.files[0] || e.dataTransfer.files[0];
      this.url = URL.createObjectURL(this.item.photo);
    },
    deletePhoto() {
      if (this.item.photo) {
        this.item.photo = "";
      }
      if (this.url) {
        this.url = "";
      }
    },
    addNewItem() {
      if (this.item.title) {
        this.global_loading.show = true;
        this.$http
          .post(
            this.$hostname +
              "/shop/categories/" +
              this.category_id +
              "/products",
            this.getFormData(),
            this.restaurant_token.config
          )
          .then((resp) => {
            this.successRequest();
            this.$router.push("/category/" + this.category_id);
          })
          .catch((err) => {
            this.showErrors(err);
          });
      } else {
        this.global_error.show = true;
        this.global_error.message = "The title field is required!";
      }
    },
    saveItem() {
      this.global_loading.show = true;
      let data = new FormData();

      data.append("photo", this.item.photo);

      data.append("title", this.item.title);
      data.append("index", this.item.index);

      data.append(
        "description_en",
        this.item.description_en
          ? this.item.description_en.replace(/\n/g, "<br />")
          : ""
      );

      data.append(
        "description_fi",
        this.item.description_fi
          ? this.item.description_fi.replace(/\n/g, "<br />")
          : ""
      );

      // data.append("price", this.replaceComma(this.item.price));

      data.append("tax", this.item.tax ? 24 : 14);

      data.append("discount", this.item.discount);
      data.append("_method", "patch");

      data.append("category_id", parseInt(this.selected_category_id));
      this.$http
        .post(
          this.$hostname + "/shop/products/" + this.item.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
          this.$router.push("/category/" + this.category_id);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    getFormData() {
      let fd = new FormData();

      fd.append("photo", this.item.photo);

      fd.append("title", this.item.title);
      fd.append("index", this.item.index);

      fd.append(
        "description_en",
        this.item.description_en
          ? this.item.description_en.replace(/\n/g, "<br />")
          : ""
      );

      fd.append(
        "description_fi",
        this.item.description_fi
          ? this.item.description_fi.replace(/\n/g, "<br />")
          : ""
      );

      fd.append("price", this.replaceComma(this.item.price));

      fd.append("tax", this.item.tax ? 24 : 14);

      fd.append("discount", this.item.discount);
      fd.append("category_id", parseInt(this.selected_category));
      // fd.append("_method", "patch");

      return fd;
    },
    submitAfterRename() {
      if (this.item.id == 0) {
        if (this.item.title) {
          this.$http
            .post(
              this.$hostname +
                "/shop/categories/" +
                this.category_id +
                "/products",
              this.getFormData(),
              this.restaurant_token.config
            )
            .then((resp) => {
              this.item.id = resp.data.result.id;
              this.id = resp.data.result.id;

              this.$router.push({
                name: "Item",
                params: {
                  category_id: this.category_id,
                  id: resp.data.result.id,
                },
              });
              this.item.title = resp.data.result.title;

              this.setEditing();
            })
            .catch((err) => {
              this.showErrors(err);
            });
        } else {
          this.global_error.show = true;
          this.global_error.message = "The title field is required!";
        }
      }
    },
    createPrice(price) {
      let data = {
        title: price.title,
        description: price.description,
        price: price.price,
        stock: price.stock,
      };
      this.$http
        .post(
          this.$hostname + "/shop/products/" + this.item.id + "/prices",
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.fetch();
        })
        .catch((err) => {});
    },
    updatePrice(price) {
      let data = {
        product_id: this.item.id,
        title: price.title,
        description: price.description,
        price: price.price,
        stock: price.stock,
      };
      this.$http
        .patch(
          this.$hostname + "/shop/prices/" + price.id,
          data,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.new_product_price.title = "";
          this.new_product_price.description = "";
          this.new_product_price.stock = 0;
          this.new_product_price.price = 0;
          this.fetch();
        })
        .catch((err) => {});
    },
    deletePrice(price) {
      this.$http
        .delete(
          this.$hostname + "/shop/prices/" + price.id,
          this.restaurant_token.config
        )
        .then((resp) => {
          this.fetch();
        })
        .catch((err) => {});
    },
    saveOnChange() {
      let fd = new FormData();

      fd.append("photo", this.item.photo);

      fd.append("title", this.item.title);
      fd.append("index", this.item.index);

      fd.append(
        "description_en",
        this.item.description_en
          ? this.item.description_en.replace(/\n/g, "<br />")
          : ""
      );

      fd.append(
        "description_fi",
        this.item.description_fi
          ? this.item.description_fi.replace(/\n/g, "<br />")
          : ""
      );

      fd.append("price", this.replaceComma(this.item.price));

      fd.append("tax", this.item.tax ? 24 : 14);

      fd.append("discount", this.item.discount);
      fd.append("category_id", parseInt(this.selected_category));
      fd.append("_method", "patch");

      this.$http
        .post(
          this.$hostname + "/shop/products/" + this.item.id,
          fd,
          this.restaurant_token.config
        )
        .then((resp) => {})
        .catch((err) => {});
    },
    closed(val) {
      if (val) {
        this.fetch();
      }
      val = false;
    },
    setEditing() {
      if (this.id == "undefined") {
        this.edit = false;
      } else {
        this.edit = true;
        this.fetch();
      }
    },
  },
  data() {
    return {
      categories: [],
      url: "",
      selected_category_id: 0,
      item: {
        id: 0,
        title: "",
        index: 1,
        photo: "",
        description_fi: "",
        description_en: "",
        price: 0,
        discount: 0,
      },
      new_product_price: {
        title: "",
        description: "",
        stock: 0,
        price: 0,
      },
      edit: false,
    };
  },
  props: {
    category_id: 0,
    id: 0,
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.item {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.content {
  max-height: 100%;
  height: 100% !important;
  min-height: 100%;
  padding: 65px 16px 0px;
}

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 24px;
  height: calc(100% - 120px);
  width: 100%;
  padding-bottom: 200px;
}

.title {
  font-size: 22px;
  color: #412250;
}

.photo_input {
  width: 100%;
  min-height: 160px;
  position: relative;
}

.fill {
  background-color: #5a187a;
  border: 1px solid #5a187a;
  max-width: 768px;
  width: 100%;
  position: fixed;
  bottom: 68px;
}

select {
  width: 100%;
  background-image: url("../../assets/common/select.svg");
  background-position-x: right;
  background-position-y: center;
}
textarea {
  width: 70%;
  height: 100px;
}

.desc {
  width: 30%;
}

.compulsories {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 6px;
}

.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.row .input {
  width: 49%;
}

.row input {
  width: 49%;
}

h4 {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid #f0edf2;
  justify-content: flex-start;
  padding-top: 8px;
  color: #737373;
}

.options {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  align-items: center;
}

.option {
  width: 48.5%;
}

.options label {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 31%;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #4d4d4d;
}

.option label {
  width: 100%;
}

.options input[type="checkbox"]:checked + label {
  border: 1px solid #5a187a;
}

.options input[type="checkbox"] {
  appearance: none;
  display: none;
  background-image: none;
}

.popular {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  justify-content: flex-start;
  position: fixed;
  bottom: 116px;
  background-color: #ffffff;
  height: 28px;
}

.outline {
  font-weight: 400;
  font-size: 12px;
  height: 28px;
  width: 100%;
  color: #5a187a;
  border: 1px solid #5a187a;
}

.tax-row {
  flex-direction: column;
  align-items: flex-start;
}

.tax {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.tax input {
  width: 24px;
}

.tax-note {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #b3b3b3;
}

.popular label,
.tax label {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #737373;
}

.tip {
  background-image: url("../../assets/common/tip_purple.svg");
  width: 24px;
}

.trash {
  background-image: url("../../assets/restaurant/home/trash.svg");
  background-size: 40px;
  background-position: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  background-blend-mode: multiply;
  background-color: #f0edf2b4;
}

.col-price {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
}
.col-price textarea {
  width: 70%;
  height: 40px;
}
</style>

<template>
  <div class="center">
    <img src="../../assets/restaurant/empty_history.svg" alt="" />
    <div class="title">{{ $t("history.empty") }}</div>
    <div class="text">{{ $t("history.empty_desc") }}</div>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.center {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 8px;
}

img {
  max-width: 160px;
  width: 100%;
  margin-bottom: 24px;
}
.title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  text-align: center;
  color: #4d4d4d;
}
.text {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  color: #a3a3a3;
}
</style>

<template>
  <div class="home">
    <div class="header">
      <div class="container">
        <div class="restaurant"></div>
        <!-- <div class="banner"></div> -->
        <div class="title">{{ $t("navbar.management") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="controls">
        <div class="btn">
          <div class="title">{{ $t("home.pickup_order") }}</div>
          <div class="switch">
            <label for="pick_up">{{ pick_up ? "On" : "Off" }}</label>
            <v-app>
              <v-switch
                @change="switchPickup"
                color="#412250"
                v-model="pick_up"
                :flat="true"
                inset
              >
              </v-switch>
            </v-app>
          </div>
        </div>
        <div class="btn">
          <div class="title">{{ $t("home.delivery_order") }}</div>
          <div class="switch">
            <label for="delivery">{{ delivery ? "On" : "Off" }}</label>

            <v-app>
              <v-switch
                @change="switchDelivery"
                color="#412250"
                v-model="delivery"
                :flat="true"
                inset
              ></v-switch>
            </v-app>
          </div>
        </div>
      </div>

      <div class="sections">
        <router-link class="btn menu" to="/categories">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.menu") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>
        <!-- 
        <router-link class="btn info" to="/info">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.manage_info") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link> -->

        <!-- <router-link class="btn rate" to="/rate">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.rate_data") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link> -->

        <router-link class="btn info" to="/restaurant-info">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.restaurant_info") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>

        <router-link class="btn delivery" to="/delivery">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("order.delivery") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>

        <router-link class="btn social_links" to="/socials-links">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.social_links") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>

        <router-link class="btn profile" to="/profile">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.profile") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>

        <router-link class="btn settings" to="/settings">
          <div class="left">
            <div class="icon"></div>
            <h4 class="caption">{{ $t("home.settings") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </router-link>

        <!-- <audio controls id="notif">
        <source src="../../assets/restaurant/bell.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio> -->
      </div>
      <!-- <h3>{{ $t("home.order_acceptance") }}</h3> -->
    </div>
  </div>
</template>
<script>
export default {
  mounted() {
    this.fetch();
    this.getNewUpdate();
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/shop/shops", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          this.delivery = resp.data.shop.delivery;
          this.pick_up = resp.data.shop.pickup;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    switchStatus() {
      this.global_loading.show = true;

      this.$http
        .patch(this.$hostname + "/open-close", "", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();

          if (this.status == 1) {
            this.status = 0;
          } else {
            this.status = 1;
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    switchPickup() {
      this.global_loading.show = true;

      this.$http
        .patch(this.$hostname + "/pick-up", "", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    switchDelivery() {
      this.global_loading.show = true;

      this.$http
        .patch(
          this.$hostname + "/delivery-service",
          "",
          this.restaurant_token.config
        )
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    switchMakeOrder() {
      this.global_loading.show = true;

      this.$http
        .patch(this.$hostname + "/make-order", "", this.restaurant_token.config)
        .then((resp) => {
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      status: 1,
      make_order: 1,
      delivery: 1,
      pick_up: 1,
    };
  },
};
</script>
<style scoped>
.header {
  background-color: #ffffff;
  padding-bottom: 0px;
}

.container {
  justify-content: space-between;
}
.container .restaurant {
  display: flex;
  align-items: center;
  height: 40px;
  width: 40px;
}
.content {
  overflow-y: scroll;
  padding: 58px 16px 72px;
}
.sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-top: 1px solid #f0edf2;
  padding-top: 16px;
  flex-direction: column;
  width: 100%;
}
a {
  font-size: 18px;
  color: #4d4d4d;
}

.icon {
  background-size: 24px;
  width: 24px;
  height: 24px;
}
.btn .left .icon {
  background-color: #ffffff;
}
.menu,
.info,
.history,
.rate,
.voucher,
.profile,
.settings,
.delivery,
.social_links {
  width: 100%;
  height: 48px;
  gap: 9px;
}

.menu .left .icon {
  background-image: url("../assets/restaurant/home/menu.svg");
}
.info .left .icon {
  background-image: url("../assets/restaurant/home/info.svg");
}
.history .left .icon {
  background-image: url("../assets/restaurant/home/history.svg");
}
.rate .left .icon {
  background-image: url("../assets/restaurant/home/rate.svg");
}
.voucher .left .icon {
  background-image: url("../assets/restaurant/home/voucher.svg");
}
.profile .left .icon {
  background-image: url("../assets/account/profile.svg");
}
.settings .left .icon {
  background-image: url("../assets/restaurant/navbar/settings_on.svg");
}

.info .left .icon {
  background-image: url("../assets/restaurant/home/info.svg");
}

.delivery .left .icon {
  background-image: url("../assets/restaurant/home/delivery.svg");
}

.services .left .icon {
  background-image: url("../assets/restaurant/home/services.svg");
}

.reservation .left .icon {
  background-image: url("../assets/restaurant/navbar/reservation_on.svg");
}

.social_links .left .icon {
  background-image: url("../assets/restaurant/home/social_links.svg");
}

.controls {
  display: flex;
  flex-direction: column;
  gap: 0px;
  padding-top: 8px;
  align-items: stretch;
  width: 100%;
  justify-content: flex-start;
}
.controls .btn {
  max-height: 50px;
  position: relative;
  padding: 12px;
  background-color: transparent;
  border-bottom: 1px solid #f8f9fa;
}
.v-input--selection-controls {
  margin-top: 12px;
  padding-top: 12px;
}

#notif {
  position: fixed;
  left: -400px;
}

.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 16px;
  color: #412250;
}

h3 {
  font-weight: 500;
  font-size: 24px;
  text-align: left;
  padding: 16px 0 0;
}
</style>
